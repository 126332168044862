/* You can add global styles to this file, and also import other style files */
@use '../src/app/shared/themes/breakpoints.scss';
@import "node_modules/ngx-countries-dropdown/assets/styles.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
}

.ipv_selected-item {
    height: 56px !important;
}

.mat-expansion-panel-body {
    padding: 0 0 37px 0 !important;
}

.inline-block {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

.padding-3 {
    padding: 3rem;
}

.margin-top-1 {
    margin-top: 1rem;
}

.orange-color {
    color: #FF6A17;
}

.gray-text {
    color: #A2A2A3;
}

.mat-mdc-snack-bar-container {
    &.snackbar-error {
        text-align: center;
        --mdc-snackbar-container-color: #EC081C;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}


.orange-text {
    color: #FF6A17 !important;
}

@media #{map-get(breakpoints.$queries, '<1350')} {

    .card-payment__container {
        width: auto !important;
    }
}